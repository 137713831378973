<template>
  <div class="card card-custom gutter-b p-5">
    <div class="row py-2">
      <div class="col-md-5">
            <b-form-select
              v-model="selectedPemerintah"
              :options="optionsPemerintah"
              @change="loadData()"
            >
            </b-form-select>
          </div>
          <div class="col-md-3">
            <div style="justify-content: center">
              
              <b-form-group class="justify-content-center">
                <b-form-radio-group
                  style="justify-content: center"
                  id="btn-radios-2"
                  v-model="selected"
                  :options="options"
                  @change="loadData()"
                  button-variant="outline-primary"
                  size="md"
                  name="radio-btn-outline"
                  buttons
                >
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
      <div class="col-md-4">
          <b-button class="float-right" variant="success" @click="isiTTE()"
            >Bubuhi Tanda Tangan Elektronik</b-button>
      </div>
    </div>
    <div style="overflow-x: auto; overflow: hidden" class="pt-2">
      <table
        id="tandaTangan"
        class="table table-striped table-bordered"
        style="width: 100%"
      >
        <thead>
          <tr>
            <th style="width: 5%">
              <b-form-checkbox
                size="sm"
                :checked="allSelected"
                @change="toggleSelectAll"
              ></b-form-checkbox>
            </th>
            <th>Nama DPMPTSP</th>
            <th>Kategori</th>
            <th>Preview</th>
            <th>Status TTE</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in tte" :key="index">
            <td>
              <div>
                <b-form-checkbox
                :checked="row.selected"
                @change="updateSelectedRow(row)"
              ></b-form-checkbox>
              </div>
              
            </td>
            <td>{{ row.nama_peserta }}</td>
            <td>{{ row.predikat }}</td>
            <td class="text-center">
              <b-button
              v-if="row.sudah_ditandatangani"
                size="sm"
                variant="secondary"
                class="m-1 pr-2"
                v-b-tooltip.hover
                title="Preview final sertifikat"
                @click="preview(row.file_final_sertifikat)"
              >
                <i class="flaticon-doc"></i>
              </b-button>

              <b-button
              v-else
                size="sm"
                variant="secondary"
                class="m-1 pr-2"
                v-b-tooltip.hover
                title="Preview sertifikat"
                @click="preview(row.file_sertifikat)"
              >
                <i class="flaticon-doc"></i>
              </b-button>
            </td>
            <td>
              <b-badge v-if="row.sudah_ditandatangani" pill variant="success">Sudah ditandatangani</b-badge>
              <b-badge v-else pill variant="danger">Belum ditandatangani</b-badge>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal id="preview" title="Preview Sertifikat" hide-footer size="xl">
    <iframe :src="file_sertifikat" frameborder="0" width="100%" :height="height"></iframe>

  </b-modal>

  <b-modal
      id="modal-nik-pass"
      ref="modal"
      title="Masukkan NIK dan Passphrase"
      cancelTitle="Batal"
      okTitle="Submit"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="submitNIKPass">
        <!-- <b-form-group
          label="NIK"
          label-for="nik-input"
        >
          <b-form-input
            id="nik-input"
            v-model="form.nik"
            :state="validateState('nik')"
          aria-describedby="input-nik-feedback"
        ></b-form-input>

        <b-form-invalid-feedback
          id="input-nik-feedback"
        >NIK wajib diisi</b-form-invalid-feedback>
        </b-form-group> -->
        <b-form-group
          label="Passphrase"
          label-for="pass-input"
        >
          <b-form-input
            id="pass-input"
            v-model="form.passphrase"
            :state="validateState('passphrase')"
          aria-describedby="input-passphrase-feedback"
        ></b-form-input>

        <b-form-invalid-feedback
          id="input-passphrase-feedback"
        >Passphrase wajib diisi</b-form-invalid-feedback>
        </b-form-group>
      </form>
    </b-modal>
  </div>

  

</template>
  
  <script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VueSelect from "vue-select";
import axios from "axios";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import VueLoading from "vuejs-loading-plugin";
import Vue from "vue";

Vue.use(VueLoading);

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: "Loading", // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: "rgb(255,255,255)", // set custom background
  classes: ["myclass"], // array, object or string
});

export default {
  name: "tandaTanganElektronik",
  components: {
    "s-select": VueSelect
  },
  mixins: [validationMixin],
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      user: JSON.parse(localStorage.getItem("user")),
      height: screen.height * 0.7,
      tte: [],
      allSelected: false,
      selectedPemerintah: 'Provinsi',
      optionsPemerintah: [
        { value: null, text: "Silahkan pilih daerah" },
        { value: "Provinsi", text: "Provinsi" },
        { value: "Kabupaten", text: "Kabupaten" },
        { value: "Kota", text: "Kota" },
        { value: "K/L", text: "Kementerian/Lembaga" },
      ],
      selected: 'Sertifikat Nomine',
      options: [
        { text: "Sertifikat Pemenang", value: "Sertifikat Nomine" },
        { text: "Sertifikat Penkin", value: "Sertifikat Penkin" },
      ],
      file_sertifikat: '',
      nik: null,
      nikState: null,
      passphrase: null,
      passphraseState: null,
      selectedRows: [],
      form: {
        nik: null,
        passphrase: null
      }
    };
  },
  validations: {
    form: {
      // nik: {
      //   required
      // },
      passphrase: {
        required,
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    isiTTE() {
      // Get selected rows
      this.selectedRows = this.tte.filter((row) => row.selected);

      if (this.selectedRows.length === 0) {
        Swal.fire({
          title: "Peringatan",
          text: "Pilih minimal satu Sertifikat untuk ditandatangani",
          icon: "warning",
        });
        return;
      }

      Swal.fire({
        title: "Apakah anda yakin?",
        text: `${this.selectedRows.length} Sertifikat akan ditanda tangani secara elektronik.`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          // Implement your signing logic here
          this.$bvModal.show('modal-nik-pass')
          console.log("Selected rows for signing:", this.selectedRows);
        }
      });
    },
    toggleSelectAll(checked) {
      this.allSelected = checked;
      this.tte = this.tte.map((row) => ({
        ...row,
        selected: checked,
      }));
    },
    updateSelectedRow(row) {
      row.selected = !row.selected;

      // Check if all rows are selected
      this.allSelected = this.tte.every((item) => item.selected);
    },
    loadData() {
      $('#tandaTangan').DataTable().destroy();
      this.tte = []
      axios.get(`${this.url}/sertifikat_penkin?filter=kelompok,=,${this.selectedPemerintah};jenis_sertifikat,=,${this.selected}`,{
        headers:{
          'xth': this.token
        }
      }).then((res) => {
        this.tte = res.data.data
        console.log(res)
        this.initDatatable();
      })
      
    },

    preview(data){
      this.file_sertifikat = data
      this.$bvModal.show('preview')
    },

    checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.form.nikState = valid
        this.form.passphraseState = valid
        return valid
    },
    handleOk(bvModalEvent) {
        // Prevent modal from closing
        bvModalEvent.preventDefault()
        // Trigger submit handler
        this.submitNIKPass()
      },

    submitNIKPass(){
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      // if (!this.checkFormValidity()) {
      //     return
      // }
      this.$nextTick(() => {
          this.$bvModal.hide('modal-nik-pass')
        })

      this.$loading(true);
      var dataID = []
      this.selectedRows.forEach(element => {
        dataID.push(element.id)
      });

      var body = {
        id: dataID,
        nik: this.user.username,
        passphrase: this.form.passphrase
      }
      axios.post(`${this.url}/sertifikat_penkin/tandatangan`,body,{
        headers:{
          'xth': this.token
        }
      }).then((res) => {
        this.$loading(false);
        dataID = []
        this.form.nik = null
        this.form.passphrase = null
        Swal.fire({
          title: "Berhasil",
          text: "Sertifikat berhasil di tandatangani elektronik",
          icon: "success"
        });

        

        this.loadData()

      }).catch((err)=>{
        this.$loading(false);
        Swal.fire({
          title: "Gagal",
          text: err.response.data.data.message,
          icon: "error"
        });
      })
    },

    initDatatable() {
      setTimeout(() => {
        const t = $("#tandaTangan").DataTable({
          pagingType: "full_numbers",
          "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
          // order: [[0, "asc"]],
          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
          "lengthMenu": [
            [10, 25, 50, -1],
            [10, 25, 50, "All"]
          ],
          
        });
       
      }, 300);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tanda Tangan Elektronik", route: "tandaTanganElektronik" },
      // { title: "Peta Sebaran" },
    ]);
    this.loadData();
  },
};
</script>