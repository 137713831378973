import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: 'hash',
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/jadwal-kegiatan",
          name: "jadwalKegiatan",
          component: () => import("@/view/pages/JadwalKegiatan.vue")
        },
        // yng dipake
        {
          path: "/kegiatan",
          name: "Kegiatan",
          component: () => import("@/view/pages/kegiatan/Kegiatan.vue")
        },

        //Web Profile
        {
          path: "/faq",
          name: "Faq",
          component: () => import("@/view/pages/faq/Faq.vue")
        },
        {
          path: "/slider",
          name: "Slider",
          component: () => import("@/view/pages/slider/Slider.vue")
        },
        {
          path: "/gallery",
          name: "Gallery",
          component: () => import("@/view/pages/webprofile/gallery/Gallery.vue")
        },
        {
          path: "/panduan",
          name: "Panduan",
          component: () => import("@/view/pages/webprofile/panduan/Panduan.vue")
        },


        {
          path: "/profil",
          name: "Profil-PTSP",
          component: () => import("@/view/pages/profil/Profil.vue")
        },
        {
          path: "/profil/asosiasi",
          name: "Profil-Asosiasi",
          component: () => import("@/view/pages/profil/ProfilAsosiasi.vue")
        },
        {
          path: "/profil/ppb",
          name: "Profil-PPB",
          component: () => import("@/view/pages/profil/ProfilPpb.vue")
        },
        {
          path: "/profil/kl",
          name: "Profil-KL",
          component: () => import("@/view/pages/profil/ProfilKl.vue")
        },
        {
          path: "/pm/ptsp",
          name: "PM-PTSP",
          component: () => import("@/view/pages/pm/Ptsp.vue")
        },
        {
          path: "/pm/ppb",
          name: "PM-PPB",
          component: () => import("@/view/pages/pm/Ppb.vue")
        },
        {
          path: "/pm/ppbkl",
          name: "PM-PPBKL",
          component: () => import("@/view/pages/pm/Ppbkl.vue")
        },
        {
          path: "/pm/ppkasosiasi",
          name: "PM-PPKAsosiasi",
          component: () => import("@/view/pages/pm/Ppkasosiasi.vue")
        },
        {
          path: "/pm/ppkprovinsi",
          name: "PM-PPKProvinsi",
          component: () => import("@/view/pages/pm/Ppkprovinsi.vue")
        },
        {
          path: "/rekapitulasi",
          name: "rekapitulasi",
          component: () => import("@/view/pages/rekapitulasi/Rekapitulasi.vue"),
          children: [
            {
              path: "ringkasan",
              name: "ringkasan",
              component: () => import("@/view/pages/rekapitulasi/Ringkasan.vue")
            },
            {
              path: "ptsp-provinsi",
              name: "ptsp-provinsi",
              component: () => import("@/view/pages/rekapitulasi/Ptspprovinsi.vue")
            },
            {
              path: "ptsp-kabupaten",
              name: "ptsp-kabupaten",
              component: () => import("@/view/pages/rekapitulasi/Ptspkabupaten.vue")
            },
            {
              path: "ptsp-kota",
              name: "ptsp-kota",
              component: () => import("@/view/pages/rekapitulasi/Ptspkota.vue")
            },
            {
              path: "ppb-kl",
              name: "ppb-kl",
              component: () => import("@/view/pages/rekapitulasi/Ppbkl.vue")
            },
            {
              path: "/rekapitulasi/detailpm/:id",
              name: "detailpm",
              component: () => import("@/view/pages/rekapitulasi/Detailpm.vue")
            },
            {
              path: "/rekapitulasi/detailppb/:id",
              name: "detailppb",
              component: () => import("@/view/pages/rekapitulasi/Detailppb.vue")
            },
            {
              path: "/rekapitulasi/detailppbkl/:id",
              name: "detailppbkl",
              component: () => import("@/view/pages/rekapitulasi/Detailppbkl.vue")
            },
            {
              path: "/rekapitulasi/detailhipmi/:id",
              name: "detailhipmi",
              component: () => import("@/view/pages/rekapitulasi/Detailhipmi.vue")
            },
            {
              path: "/rekapitulasi/detailppkprovinsi/:id",
              name: "detailppkprovinsi",
              component: () => import("@/view/pages/rekapitulasi/Detailppkprovinsi.vue")
            },
          ]
        },
        {
          path: "/rekapitulasispv",
          name: "rekapitulasispv",
          component: () => import("@/view/pages/rekapitulasiSPV/Rekapitulasi.vue"),
          children: [
            {
              path: "ringkasan",
              name: "ringkasan",
              component: () => import("@/view/pages/rekapitulasiSPV/Ringkasan.vue")
            },
            {
              path: "ptsp-provinsi",
              name: "ptsp-provinsi",
              component: () => import("@/view/pages/rekapitulasiSPV/Ptspprovinsi.vue")
            },
            {
              path: "ptsp-kabupaten",
              name: "ptsp-kabupaten",
              component: () => import("@/view/pages/rekapitulasiSPV/Ptspkabupaten.vue")
            },
            {
              path: "ptsp-kota",
              name: "ptsp-kota",
              component: () => import("@/view/pages/rekapitulasiSPV/Ptspkota.vue")
            },
            {
              path: "ppb-kl",
              name: "ppb-kl",
              component: () => import("@/view/pages/rekapitulasiSPV/Ppbkl.vue")
            },
          ]
        },
        {
          path: "/users",
          name: "User",
          component: () => import("@/view/pages/users/User.vue")
        },
        {
          path: "/tambahPengguna",
          name: "tambahPengguna",
          component: () => import("@/view/pages/users/TambahUser.vue")
        },
        {
          path: "/ubahPengguna/:id",
          name: "ubahPengguna",
          component: () => import("@/view/pages/users/UbahUser.vue")
        },

        {
          path: "profil-user/:id",
          name: "profil-user",
          component: () => import("@/view/pages/users/Profiluser.vue")
        },
        {
          path: "/pengaturan",
          name: "pengaturan",
          component: () => import("@/view/pages/pengaturan/Pengaturan.vue"),
          children: [
            {
              path: "api-list",
              name: "api-list",
              component: () => import("@/view/pages/pengaturan/Apilist.vue")
            },
            {
              path: "menu-list",
              name: "menu-list",
              component: () => import("@/view/pages/pengaturan/Menulist.vue")
            },
            {
              path: "role",
              name: "role",
              component: () => import("@/view/pages/pengaturan/Role.vue")
            },
            {
              path: "verifikasiuser",
              name: "verifikasiuser",
              component: () => import("@/view/pages/pengaturan/Verifikasiuser.vue")
            },
            {
              path: "akun",
              name: "akun",
              component: () => import("@/view/pages/pengaturan/Akun.vue")
            },
            {
              path: "profil-surveyor/:id",
              name: "profil-surveyor",
              component: () => import("@/view/pages/pengaturan/Profilsurveyor.vue")
            },
          ]
        },
        {
          path: "/penilaianpaparan",
          name: "penilaianpaparan",
          component: () => import("@/view/pages/penilaianpaparan/Penilaianpaparan.vue"),
          children: [
            {
              path: "penilaianpaparan-provinsi",
              name: "penilaianpaparan-provinsi",
              component: () => import("@/view/pages/penilaianpaparan/Provinsi.vue")
            },
            {
              path: "penilaianpaparan-kabupaten",
              name: "penilaianpaparan-kabupaten",
              component: () => import("@/view/pages/penilaianpaparan/Kabupaten.vue")
            },
            {
              path: "penilaianpaparan-kota",
              name: "penilaianpaparan-kota",
              component: () => import("@/view/pages/penilaianpaparan/Kota.vue")
            },
            {
              path: "penilaianpaparan-kl",
              name: "penilaianpaparan-kl",
              component: () => import("@/view/pages/penilaianpaparan/Kl.vue")
            },
          ]
        },
        {
          path: "/penilaianujipetik",
          name: "penilaianujipetik",
          component: () => import("@/view/pages/penilaianujipetik/Penilaianujipetik.vue"),
          children: [
            {
              path: "penilaianujipetik-provinsi",
              name: "penilaianujipetik-provinsi",
              component: () => import("@/view/pages/penilaianujipetik/Provinsi.vue")
            },
            {
              path: "penilaianujipetik-kabupaten",
              name: "penilaianujipetik-kabupaten",
              component: () => import("@/view/pages/penilaianujipetik/Kabupaten.vue")
            },
            {
              path: "penilaianujipetik-kota",
              name: "penilaianujipetik-kota",
              component: () => import("@/view/pages/penilaianujipetik/Kota.vue")
            },
            {
              path: "penilaianujipetik-kl",
              name: "penilaianujipetik-kl",
              component: () => import("@/view/pages/penilaianujipetik/Kl.vue")
            },
          ]
        },
        {
          path: "/pm/listprovinsippk",
          name: "listprovinsippk",
          component: () => import("@/view/pages/pm/Listprovinsippk.vue")
        },
        {
          path: "/pm/detailpm/:id",
          name: "detailpm",
          component: () => import("@/view/pages/pm/Detailpm.vue")
        },
        {
          path: "/pm/detailppb/:id",
          name: "detailppb",
          component: () => import("@/view/pages/pm/Detailppb.vue")
        },
        {
          path: "mapping",
          name: "mapping",
          component: () => import("@/view/pages/mappingsurveyor/Mapping.vue")
        },
        {
          path: "/mapping/detailpm/:id",
          name: "mapping-detailpm",
          component: () => import("@/view/pages/mappingsurveyor/Detailpm.vue")
        },
        {
          path: "/mapping/detailpmppb/:id",
          name: "mapping-detailpmppb",
          component: () => import("@/view/pages/mappingsurveyor/Detailppb.vue")
        },
        {
          path: "/mapping/detailpmppbkl/:id",
          name: "mapping-detailpmppbkl",
          component: () => import("@/view/pages/mappingsurveyor/Detailppbkl.vue")
        },
        {
          path: "/verifikasi",
          name: "verifikasi",
          component: () => import("@/view/pages/verifikasi/Verifikasi.vue"),
          children: [
            {
              path: "ptsppemda",
              name: "ptsppemda",
              component: () => import("@/view/pages/verifikasi/Ptsppemda.vue"),
            },
            {
              path: "detailpm/:id",
              name: "detailpm",
              component: () => import("@/view/pages/mappingsurveyor/Detailpm.vue")
            },
            {
              path: "ppbpemda",
              name: "ppbpemda",
              component: () => import("@/view/pages/verifikasi/Ppbpemda.vue")
            },
            {
              path: "detailppb/:id",
              name: "detailppb",
              component: () => import("@/view/pages/mappingsurveyor/Detailppb.vue")
            },
            {
              path: "ppbkl",
              name: "ppbkl",
              component: () => import("@/view/pages/verifikasi/Ppbkl.vue")
            },
            {
              path: "detailppbkl/:id",
              name: "detailppbkl",
              component: () => import("@/view/pages/mappingsurveyor/Detailppbkl.vue")
            },
          ]
        },

        // Mapping QC

        {
          path: "/mappingqualitycontrol",
          name: "mappingqualitycontrol",
          component: () => import("@/view/pages/mappingqc/mapping.vue"),
          children: [
            {
              path: "ptsp-provinsi",
              name: "ptsp-provinsi",
              component: () => import("@/view/pages/mappingqc/ptspprovinsi.vue")
            },
            {
              path: "ptsp-kabupaten",
              name: "ptsp-kabupaten",
              component: () => import("@/view/pages/mappingqc/ppbkabupaten.vue")
            },

            {
              path: "ptsp-kota",
              name: "ptsp-kota",
              component: () => import("@/view/pages/mappingqc/ppbkota.vue")
            },
            {
              path: "ppb-kl",
              name: "ppbkl",
              component: () => import("@/view/pages/mappingqc/ppbkl.vue")
            },

          ]
        },

        // Quality Control

        {
          path: "/qualitycontrol",
          name: "qualitycontrol",
          component: () => import("@/view/pages/qualitycontrol/qualitycontrol.vue"),
          children: [
            {
              path: "ptsp-provinsi",
              name: "ptsp-provinsi",
              component: () => import("@/view/pages/qualitycontrol/ptspprovinsi.vue")
            },
            {
              path: "ptsp-kabupaten",
              name: "ptsp-kabupaten",
              component: () => import("@/view/pages/qualitycontrol/ptspkabupaten.vue")
            },

            {
              path: "ptsp-kota",
              name: "ptsp-kota",
              component: () => import("@/view/pages/qualitycontrol/ptspkota.vue")
            },
            {
              path: "ppb-kl",
              name: "ppb-kl",
              component: () => import("@/view/pages/qualitycontrol/ppbkl.vue")
            },
            {
              path: "qc-pmptsp/:id",
              name: "qc-pmptsp",
              component: () => import("@/view/pages/qualitycontrol/qc-pmptsp.vue")
            },
            {
              path: "qc-ppbpemda/:id",
              name: "qc-ppbpemda",
              component: () => import("@/view/pages/qualitycontrol/qc-ppbpemda.vue")
            },
            {
              path: "qc-ppbkl/:id",
              name: "qc-ppbkl",
              component: () => import("@/view/pages/qualitycontrol/qc-ppbkl.vue")
            },

            {
              path: "detail-qc-pmptsp/:id",
              name: "detail-qc-pmptsp",
              component: () => import("@/view/pages/qualitycontrol/pendamping-pmptsp.vue")
            },
            {
              path: "detail-qc-ppbpemda/:id",
              name: "detail-qc-ppbpemda",
              component: () => import("@/view/pages/qualitycontrol/pendamping-ppbpemda.vue")
            },
            {
              path: "detail-qc-ppbkl/:id",
              name: "detail-qc-ppbkl",
              component: () => import("@/view/pages/qualitycontrol/pendamping-ppbkl.vue")
            },

          ]
        },
        // form Verifikasi

        {
          path: "ptsppemda-detail",
          name: "ptsppemda-detail",
          component: () => import("@/view/pages/verifikasi/verifikasi_pmptsp.vue")
        },
        {
          path: "ppbpemda-detail",
          name: "ppbpemda-detail",
          component: () => import("@/view/pages/verifikasi/verifikasi_ppbpemda.vue")
        },
        {
          path: "ppbkl-detail",
          name: "ppbkl-detail",
          component: () => import("@/view/pages/verifikasi/verifikasi_ppbkl.vue")
        },

        {
          path: "ptsppemda-verdok",
          name: "ptsppemda-verdok",
          component: () => import("@/view/pages/verifikasi/verdok_pmptsp.vue")
        },
        {
          path: "ppbpemda-verdok",
          name: "ppbpemda-verdok",
          component: () => import("@/view/pages/verifikasi/verdok_ppbpemda.vue")
        },
        {
          path: "ppbkl-verdok",
          name: "ppbkl-verdok",
          component: () => import("@/view/pages/verifikasi/verdok_ppbkl.vue")
        },

        {
          path: "/support",
          name: "support",
          component: () => import("@/view/pages/support/Support.vue"),
          children: [
            {
              path: "ticket",
              name: "ticket",
              component: () => import("@/view/pages/support/Ticket.vue")
            },
            {
              path: "open-tickets",
              name: "open-tickets",
              component: () => import("@/view/pages/support/Openticket.vue")
            },
            {
              path: "view-ticket",
              name: "view-ticket",
              component: () => import("@/view/pages/support/Viewticket.vue")
            },
            {
              path: "detail-ticket/:id",
              name: "detail-ticket",
              component: () => import("@/view/pages/support/Detailticket.vue")
            },
            {
              path: "detail-tickets/:id",
              name: "detail-tickets",
              component: () => import("@/view/pages/support/Detailtickets.vue")
            },
          ]
        },
        {
          path: "/list-peserta",
          name: "list-peserta",
          component: () =>
            import("@/view/pages/list-peserta/Listpeserta.vue"),
          children: [
            {
              path: "ptspprov",
              name: "list-peserta-ptspprov",
              component: () => import("@/view/pages/list-peserta/Ptspprov.vue")
            },
            {
              path: "ptspkab",
              name: "list-peserta-ptspkab",
              component: () => import("@/view/pages/list-peserta/Ptspkab.vue")
            },
            {
              path: "ptspkot",
              name: "list-peserta-ptspkot",
              component: () => import("@/view/pages/list-peserta/Ptspkot.vue")
            },
            {
              path: "ppbprov",
              name: "list-peserta-ppbprov",
              component: () => import("@/view/pages/list-peserta/Ppbprov.vue")
            },
            {
              path: "profilptspdetail/:id",
              name: "profilptspdetail",
              component: () => import("@/view/pages/list-peserta/Ptspdetail.vue")
            },
            {
              path: "profilppbdetail/:id",
              name: "profilppbdetail",
              component: () => import("@/view/pages/list-peserta/Ppbdetail.vue")
            },
            {
              path: "profilppbkldetail/:id",
              name: "profilppbkldetail",
              component: () => import("@/view/pages/list-peserta/Ppbkldetail.vue")
            },
            {
              path: "ppbkab",
              name: "list-peserta-ppbkab",
              component: () => import("@/view/pages/list-peserta/Ppbkab.vue")
            },
            {
              path: "ppbkl",
              name: "list-peserta-ppbkab",
              component: () => import("@/view/pages/list-peserta/Ppbkl.vue")
            },
            {
              path: "hipmiprov",
              name: "list-peserta-hipmiprov",
              component: () => import("@/view/pages/list-peserta/Hipmiprov.vue")
            },
            {
              path: "hipmikab",
              name: "list-peserta-hipmikab",
              component: () => import("@/view/pages/list-peserta/Hipmikab.vue")
            },
          ]
        },
        {
          path: "/list-profil",
          name: "list-profil",
          component: () =>
            import("@/view/pages/list-profil/Listprofil.vue"),
          children: [
            {
              path: "ptspprov",
              name: "list-profil-ptspprov",
              component: () => import("@/view/pages/list-profil/Ptspprov.vue")
            },
            {
              path: "ptspkab",
              name: "list-profil-ptspkab",
              component: () => import("@/view/pages/list-profil/Ptspkab.vue")
            },
            {
              path: "ppbkl",
              name: "list-profil-ppbkl",
              component: () => import("@/view/pages/list-profil/Ppbkl.vue")
            },
          ]
        },
        {
          path: "/penentuannominee",
          name: "penentuannominee",
          component: () => import("@/view/pages/penentuannominee/Penentuannominee.vue"),
          children: [
            {
              path: "penentuannominee-provinsi",
              name: "penentuannominee-provinsi",
              component: () => import("@/view/pages/penentuannominee/Provinsi.vue")
            },
            {
              path: "penentuannominee-kabupaten",
              name: "penentuannominee-kabupaten",
              component: () => import("@/view/pages/penentuannominee/Kabupaten.vue")
            },
            {
              path: "penentuannominee-kota",
              name: "penentuannominee-kota",
              component: () => import("@/view/pages/penentuannominee/Kota.vue")
            },
            {
              path: "penentuannominee-kementeriandanlembaga",
              name: "penentuannominee-kementeriandanlembaga",
              component: () => import("@/view/pages/penentuannominee/Kementriandanlembaga.vue")
            },
          ]
        },
        {
          path: "/penetapannominee",
          name: "penetapannominee",
          component: () => import("@/view/pages/penetapannominee/Nominee.vue"),
          children: [
            {
              path: "provinsi",
              name: "provinsi",
              component: () => import("@/view/pages/penetapannominee/provinsi.vue")
            },
            {
              path: "kabupaten",
              name: "kabupaten",
              component: () => import("@/view/pages/penetapannominee/kabupaten.vue")
            },
            {
              path: "kota",
              name: "kota",
              component: () => import("@/view/pages/penetapannominee/kota.vue")
            },
            {
              path: "kementeriandanlembaga",
              name: "kementeriandanlembaga",
              component: () => import("@/view/pages/penetapannominee/kementriandanlembaga.vue")
            },
          ]
        },
        {
          path: "/persetujuannominee",
          name: "persetujuannominee",
          component: () => import("@/view/pages/persetujuannominee/Persetujuannominee.vue"),
          children: [
            {
              path: "persetujuannominee-provinsi",
              name: "persetujuannominee-provinsi",
              component: () => import("@/view/pages/persetujuannominee/Provinsi.vue")
            },
            {
              path: "persetujuannominee-kabupaten",
              name: "persetujuannominee-kabupaten",
              component: () => import("@/view/pages/persetujuannominee/Kabupaten.vue")
            },
            {
              path: "persetujuannominee-kota",
              name: "persetujuannominee-kota",
              component: () => import("@/view/pages/persetujuannominee/Kota.vue")
            },
            {
              path: "persetujuannominee-kementeriandanlembaga",
              name: "persetujuannominee-kementeriandanlembaga",
              component: () => import("@/view/pages/persetujuannominee/Kementriandanlembaga.vue")
            },
          ]
        },
        {
          path: "/pengaturanruangan",
          name: "pengaturanruangan",
          component: () => import("@/view/pages/pengaturanruangan/Pengaturanruangan.vue"),
          children: [
            {
              path: "pengaturanruangan-ruangan",
              name: "pengaturanruangan-ruangan",
              component: () => import("@/view/pages/pengaturanruangan/Ruangan.vue")
            },
            {
              path: "pengaturanruangan-mapping",
              name: "pengaturanruangan-mapping",
              component: () => import("@/view/pages/pengaturanruangan/Mapping.vue")
            },
          ]
        },
        {
          path: "/pengaturanujipetik",
          name: "pengaturanujipetik",
          component: () => import("@/view/pages/pengaturanujipetik/Pengaturanujipetik.vue"),
          children: [
            {
              path: "pengaturanujipetik-ruangan",
              name: "pengaturanujipetik-ruangan",
              component: () => import("@/view/pages/pengaturanujipetik/Ruangan.vue")
            },
            {
              path: "pengaturanujipetik-mapping",
              name: "pengaturanujipetik-mapping",
              component: () => import("@/view/pages/pengaturanujipetik/Mapping.vue")
            },
          ]
        },
        {
          path: "/pemaparan",
          name: "pemaparan",
          component: () => import("@/view/pages/pemaparan/Pemaparan.vue"),
          children: [
            {
              path: "pemaparan-provinsi",
              name: "pemaparan-provinsi",
              component: () => import("@/view/pages/pemaparan/provinsi.vue")
            },
            {
              path: "pemaparan-kabupaten",
              name: "pemaparan-kabupaten",
              component: () => import("@/view/pages/pemaparan/kabupaten.vue")
            },
            {
              path: "pemaparan-kota",
              name: "pemaparan-kota",
              component: () => import("@/view/pages/pemaparan/kota.vue")
            },
            {
              path: "pemaparan-kementeriandanlembaga",
              name: "pemaparan-kementeriandanlembaga",
              component: () => import("@/view/pages/pemaparan/kl.vue")
            },
          ]
        },
        {
          path: "/verifikasilapangan",
          name: "verifikasilapangan",
          component: () => import("@/view/pages/verifikasilapangan/Verifikasilapangan.vue"),
          children: [
            {
              path: "verval-provinsi",
              name: "verval-provinsi",
              component: () => import("@/view/pages/verifikasilapangan/Vervalprovinsi.vue")
            },
            {
              path: "verval-kabupaten",
              name: "verval-kabupaten",
              component: () => import("@/view/pages/verifikasilapangan/Vervalkabupaten.vue")
            },
            {
              path: "verval-kota",
              name: "verval-kota",
              component: () => import("@/view/pages/verifikasilapangan/Vervalkota.vue")
            },
            {
              path: "vervalkl",
              name: "vervalkl",
              component: () => import("@/view/pages/verifikasilapangan/Vervalkl.vue")
            },
          ]
        },
        {
          path: "/listujipetik",
          name: "listujipetik",
          component: () => import("@/view/pages/listujipetik/Listujipetik.vue"),
          children: [
            {
              path: "listujipetik-provinsi",
              name: "listujipetik-provinsi",
              component: () => import("@/view/pages/listujipetik/provinsi.vue")
            },
            {
              path: "listujipetik-kabupaten",
              name: "listujipetik-kabupaten",
              component: () => import("@/view/pages/listujipetik/kabupaten.vue")
            },
            {
              path: "listujipetik-kota",
              name: "listujipetik-kota",
              component: () => import("@/view/pages/listujipetik/kota.vue")
            },
            {
              path: "listujipetik-kementeriandanlembaga",
              name: "listujipetik-kementeriandanlembaga",
              component: () => import("@/view/pages/listujipetik/kl.vue")
            },
          ]
        },
        {
          path: "/pemaparandokumen",
          name: "Pemaparandokumen",
          component: () => import("@/view/pages/pemaparandokumen/Pemaparandokumen.vue")
        },
        {
          path: "/ujipetik",
          name: "Ujipetik",
          component: () => import("@/view/pages/ujipetik/ujipetik.vue")
        },
        {
          path: "/rekomendasi",
          name: "rekomendasi",
          component: () => import("@/view/pages/rekomendasi/rekomendasi.vue"),
          children: [
            {
              path: "ptsp",
              name: "ptsp",
              component: () => import("@/view/pages/rekomendasi/ptsp.vue")
            },
            {
              path: "ppb",
              name: "ppb",
              component: () => import("@/view/pages/rekomendasi/ppb.vue")
            },
            {
              path: "ppbkl",
              name: "ppbkl",
              component: () => import("@/view/pages/rekomendasi/ppbkl.vue")
            },
          ]
        },

        {
          path: "/laporan",
          name: "laporan",
          component: () => import("@/view/pages/laporan/laporan.vue"),
          children: [
            {
              path: "maps",
              name: "maps",
              component: () => import("@/view/pages/laporan/maps.vue")
            },
            {
              path: "statistik",
              name: "statistik",
              component: () => import("@/view/pages/laporan/statistik.vue")
            },
          ]
        },
        //Peserta

        {
          path: "/kegiatan-bimtek",
          name: "Pilih-Kegiatan",
          component: () => import("@/view/pages/bimtek/kegiatan/kegiatanBimtek.vue")
        },
        {
          path: "/kegiatan-bimtek/:id",
          name: "Enroll-Kegiatan",
          component: () => import("@/view/pages/bimtek/kegiatan/detailKegiatanBimtek.vue")
        },
        {
          path: "/kegiatan-bimtek/pretest/:id",
          name: "Pre-Test",
          component: () => import("@/view/pages/bimtek/pretest/pretest.vue")
        },
        {
          path: "/kegiatan-bimtek/posttest/:id",
          name: "Post-Test",
          component: () => import("@/view/pages/bimtek/posttest/posttest.vue")
        },
        {
          path: "/petunjukpre",
          name: "Petunjukpre",
          component: () => import("@/view/pages/bimtek/pretest/petunjukPreTest.vue")
        },
        {
          path: "/petunjukpost",
          name: "Petunjukpost",
          component: () => import("@/view/pages/bimtek/posttest/petunjukPostTest.vue")
        },
        {
          path: "/materi",
          name: "Materi",
          component: () => import("@/view/pages/bimtek/materi.vue")
        },
        {
          path: "/survey",
          name: "Survey",
          component: () => import("@/view/pages/bimtek/survey.vue")
        },
        {
          path: "/profilPeserta",
          name: "profilPeserta",
          component: () => import("@/view/pages/bimtek/profil/profil.vue")
        },

        {
          path: "/panduanbimtek",
          name: "Panduan Bimtek",
          component: () => import("@/view/pages/bimtek/panduan.vue")
        },

        // Admin Bimtek dan EO
        {
          path: "/kegiatanbmtk",
          name: "Kegiatan",
          component: () => import("@/view/pages/bimtekadmin/kegiatanbmtk/kegiatanbmtk.vue"),
        },
        {
          path: "/kegiatanbmtk/peserta/:id",
          name: "Peserta",
          component: () => import("@/view/pages/bimtekadmin/kegiatanbmtk/detailKegiatan.vue"),
        },
        {
          path: "/kegiatanbmtk/pendaftar/:id",
          name: "Pengguna",
          component: () => import("@/view/pages/bimtekadmin/kegiatanbmtk/pendaftarBmtk.vue"),
        },

        {
          path: "materibmtk",
          name: "materiBimtek",
          component: () => import("@/view/pages/bimtekadmin/materibmtk/materibmtk.vue"),
        },
        {
          path: "/materibmtk/soal/:id",
          name: "soalBimtek",
          component: () => import("@/view/pages/bimtekadmin/materibmtk/soalbmtk.vue"),
        },
        // {
        //   path: "/listMateri",
        //   name: "listMateri",
        //   component: () => import("@/view/pages/bimtekadmin/materibmtk/ListMateri.vue"),
        //   children: [
        //     {
        //       path: "materiBmtk",
        //       name: "materiBimtek",
        //       component: () => import("@/view/pages/bimtekadmin/materibmtk/materibmtk.vue"),
        //     },

        //   ]
        // },
        {
          path: "/listprofilbmtk",
          name: "listprofilbmtk",
          component: () => import("@/view/pages/bimtekadmin/profilbmtk/listProfilbmtk.vue"),
          children: [
            {
              path: "pesertabmtk",
              name: "peserta-bmtk",
              component: () => import("@/view/pages/bimtekadmin/profilbmtk/pesertabmtk.vue"),
            },
            {
              path: "profil-peserta/:id",
              name: "profil-peserta",
              component: () => import("@/view/pages/bimtekadmin/profilbmtk/peserta/detailPeserta.vue")
            },
            {
              path: "pemateribmtk",
              name: "pemateri-bmtk",
              component: () => import("@/view/pages/bimtekadmin/profilbmtk/pemateribmtk.vue"),
            }
          ]
        },
        {
          path: "/settingSertifikat",
          name: "settingSertifikat",
          component: () => import("@/view/pages/bimtekadmin/settingSertifikat/sertifikatBimtek.vue"),
        },
        {
          path: "/tandaTanganElektronik",
          name: "tandaTanganElektronik",
          component: () => import("@/view/pages/tte/TandaTanganElektronik.vue"),
        },



        // end yang dipake
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue")
        },
        {
          path: "/vue-bootstrap",
          name: "vue-bootstrap",
          component: () =>
            import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children: [
            {
              path: "alert",
              name: "vue-bootstrap-alert",
              component: () => import("@/view/pages/vue-bootstrap/Alert.vue")
            },
            {
              path: "badge",
              name: "vue-bootstrap-badge",
              component: () => import("@/view/pages/vue-bootstrap/Badge.vue")
            },
            {
              path: "button",
              name: "vue-bootstrap-button",
              component: () => import("@/view/pages/vue-bootstrap/Button.vue")
            },
            {
              path: "button-group",
              name: "vue-bootstrap-button-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonGroup.vue")
            },
            {
              path: "button-toolbar",
              name: "vue-bootstrap-button-toolbar",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonToolbar.vue")
            },
            {
              path: "card",
              name: "vue-bootstrap-card",
              component: () => import("@/view/pages/vue-bootstrap/Card.vue")
            },
            {
              path: "carousel",
              name: "vue-bootstrap-carousel",
              component: () => import("@/view/pages/vue-bootstrap/Carousel.vue")
            },
            {
              path: "collapse",
              name: "vue-bootstrap-collapse",
              component: () => import("@/view/pages/vue-bootstrap/Collapse.vue")
            },
            {
              path: "dropdown",
              name: "vue-bootstrap-dropdown",
              component: () => import("@/view/pages/vue-bootstrap/Dropdown.vue")
            },
            {
              path: "embed",
              name: "vue-bootstrap-embed",
              component: () => import("@/view/pages/vue-bootstrap/Embed.vue")
            },
            {
              path: "form",
              name: "vue-bootstrap-form",
              component: () => import("@/view/pages/vue-bootstrap/Form.vue")
            },
            {
              path: "form-checkbox",
              name: "vue-bootstrap-form-checkbox",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormCheckbox.vue")
            },
            {
              path: "form-file",
              name: "vue-bootstrap-form-file",
              component: () => import("@/view/pages/vue-bootstrap/FormFile.vue")
            },
            {
              path: "form-group",
              name: "vue-bootstrap-form-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormGroup.vue")
            },
            {
              path: "form-input",
              name: "vue-bootstrap-form-input",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormInput.vue")
            },
            {
              path: "form-radio",
              name: "vue-bootstrap-form-radio",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormRadio.vue")
            },
            {
              path: "form-select",
              name: "vue-bootstrap-form-select",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormSelect.vue")
            },
            {
              path: "form-textarea",
              name: "vue-bootstrap-form-textarea",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormTextarea.vue")
            },
            {
              path: "image",
              name: "vue-bootstrap-image",
              component: () => import("@/view/pages/vue-bootstrap/Image.vue")
            },
            {
              path: "input-group",
              name: "vue-bootstrap-input-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/InputGroup.vue")
            },
            {
              path: "jumbotron",
              name: "vue-bootstrap-jumbotron",
              component: () =>
                import("@/view/pages/vue-bootstrap/Jumbotron.vue")
            },
            {
              path: "layout-grid-system",
              name: "vue-bootstrap-layout-grid-system",
              component: () =>
                import("@/view/pages/vue-bootstrap/LayoutGridSystem.vue")
            },
            {
              path: "link",
              name: "vue-bootstrap-link",
              component: () => import("@/view/pages/vue-bootstrap/Link.vue")
            },
            {
              path: "list-group",
              name: "vue-bootstrap-list-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ListGroup.vue")
            },
            {
              path: "media",
              name: "vue-bootstrap-media",
              component: () => import("@/view/pages/vue-bootstrap/Media.vue")
            },
            {
              path: "modal",
              name: "vue-bootstrap-modal",
              component: () => import("@/view/pages/vue-bootstrap/Modal.vue")
            },
            {
              path: "nav",
              name: "vue-bootstrap-nav",
              component: () => import("@/view/pages/vue-bootstrap/Nav.vue")
            },
            {
              path: "navbar",
              name: "vue-bootstrap-navbar",
              component: () => import("@/view/pages/vue-bootstrap/Navbar.vue")
            },
            {
              path: "pagination",
              name: "vue-bootstrap-pagination",
              component: () =>
                import("@/view/pages/vue-bootstrap/Pagination.vue")
            },
            {
              path: "pagination-nav",
              name: "vue-bootstrap-pagination-nav",
              component: () =>
                import("@/view/pages/vue-bootstrap/PaginationNav.vue")
            },
            {
              path: "notify-popover",
              name: "vue-bootstrap-popover",
              component: () => import("@/view/pages/vue-bootstrap/Popover.vue")
            },
            {
              path: "notify-toasts",
              name: "vue-bootstrap-toasts",
              component: () => import("@/view/pages/vue-bootstrap/Toasts.vue")
            },
            {
              path: "notify-tooltip",
              name: "vue-bootstrap-tooltip",
              component: () => import("@/view/pages/vue-bootstrap/Tooltip.vue")
            },
            {
              path: "progress",
              name: "vue-bootstrap-progress",
              component: () => import("@/view/pages/vue-bootstrap/Progress.vue")
            },
            {
              path: "progress-spinner",
              name: "vue-bootstrap-spinner",
              component: () => import("@/view/pages/vue-bootstrap/Spinner.vue")
            },
            {
              path: "table",
              name: "vue-bootstrap-table",
              component: () => import("@/view/pages/vue-bootstrap/Table.vue")
            },
            {
              path: "tabs",
              name: "vue-bootstrap-tabs",
              component: () => import("@/view/pages/vue-bootstrap/Tabs.vue")
            }
          ]
        },
        {
          path: "/vuetify",
          name: "vuetify",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "alerts",
              name: "vuetify-alerts",
              component: () => import("@/view/pages/vuetify/Alerts.vue")
            },
            {
              path: "avatars",
              name: "vuetify-avatars",
              component: () => import("@/view/pages/vuetify/Avatars.vue")
            },
            {
              path: "badges",
              name: "vuetify-badges",
              component: () => import("@/view/pages/vuetify/Badges.vue")
            },
            {
              path: "buttons",
              name: "vuetify-buttons",
              component: () => import("@/view/pages/vuetify/Buttons.vue")
            },
            {
              path: "calendars",
              name: "vuetify-calendars",
              component: () => import("@/view/pages/vuetify/Calendars.vue")
            },
            {
              path: "cards",
              name: "vuetify-cards",
              component: () => import("@/view/pages/vuetify/Cards.vue")
            },
            {
              path: "chips",
              name: "vuetify-chips",
              component: () => import("@/view/pages/vuetify/Chips.vue")
            },
            {
              path: "dialog",
              name: "vuetify-dialog",
              component: () => import("@/view/pages/vuetify/Dialog.vue")
            },
            {
              path: "forms-autocompletes",
              name: "vuetify-autocompletes",
              component: () =>
                import("@/view/pages/vuetify/forms/Autocompletes.vue")
            },
            {
              path: "forms-file-inputs",
              name: "vuetify-file-inputs",
              component: () =>
                import("@/view/pages/vuetify/forms/FileInputs.vue")
            },
            {
              path: "forms",
              name: "vuetify-forms",
              component: () => import("@/view/pages/vuetify/forms/Forms.vue")
            },
            {
              path: "forms-selection-controls",
              name: "vuetify-selection-controls",
              component: () =>
                import("@/view/pages/vuetify/forms/SelectionControls.vue")
            },
            {
              path: "forms-selects",
              name: "vuetify-selects",
              component: () => import("@/view/pages/vuetify/forms/Selects.vue")
            },
            {
              path: "forms-textareas",
              name: "vuetify-textareas",
              component: () =>
                import("@/view/pages/vuetify/forms/Textareas.vue")
            },
            {
              path: "forms-text-fields",
              name: "vuetify-text-fields",
              component: () =>
                import("@/view/pages/vuetify/forms/TextFields.vue")
            },
            {
              path: "tables-simple-tables",
              name: "vuetify-simple-tables",
              component: () =>
                import("@/view/pages/vuetify/tables/SimpleTables.vue")
            },
            {
              path: "tables-data-tables",
              name: "vuetify-data-tables",
              component: () =>
                import("@/view/pages/vuetify/tables/DataTables.vue")
            },
            {
              path: "tabs",
              name: "vuetify-tabs",
              component: () => import("@/view/pages/vuetify/Tabs.vue")
            },
            {
              path: "timelines",
              name: "vuetify-timelines",
              component: () => import("@/view/pages/vuetify/Timelines.vue")
            },
            {
              path: "tooltips",
              name: "vuetify-tooltips",
              component: () => import("@/view/pages/vuetify/Tooltips.vue")
            },
            {
              path: "treeview",
              name: "vuetify-treeview",
              component: () => import("@/view/pages/vuetify/Treeview.vue")
            }
          ]
        },
        {
          path: "/custom-wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/view/pages/wizard/Wizard-1.vue")
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/view/pages/wizard/Wizard-2.vue")
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/view/pages/wizard/Wizard-3.vue")
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/view/pages/wizard/Wizard-4.vue")
            }
          ]
        },
        {
          path: "/custom-plugins",
          name: "plugins",
          component: () => import("@/view/pages/plugins/Plugins.vue"),
          children: [
            {
              path: "cropper",
              name: "cropper",
              component: () => import("@/view/pages/plugins/Cropper.vue")
            },
            {
              path: "treeselect",
              name: "treeselect",
              component: () => import("@/view/pages/plugins/Treeselect.vue")
            }
          ]
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "profile-1",
              name: "profile-1",
              component: () => import("@/view/pages/profile/Profile-1.vue")
            },
            {
              path: "profile-2",
              name: "profile-2",
              component: () => import("@/view/pages/profile/Profile-2.vue")
            },
            {
              path: "profile-3",
              name: "profile-3",
              component: () => import("@/view/pages/profile/Profile-3.vue")
            },
            {
              path: "profile-4",
              name: "profile-4",
              component: () => import("@/view/pages/profile/Profile-4.vue")
            }
          ]
        }
      ]
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/verifikasi-tte",
      name: "verifikasi",
      component: () => import("@/view/pages/tte/verifikasi-tte.vue")
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },

    // {
    //   path: "/",
    //   name: "error-6",
    //   component: () => import("@/view/pages/error/Error-6.vue")
    // },

    {
      // the 404 route, when none of the above matches
      path: "/coeg",
      name: "coeg",
      component: () => import("@/view/pages/error/errornich.vue")
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
