<template>
    <div class="row" style="height: 100%;width: 100%;;background-image: url('/media/bg/bgbkpm.webp');background-position: center;
  background-repeat: no-repeat;
  background-size: cover;">
    <div class="md-col-3" style="margin: auto;">
        <b-card
            v-if="data_sertifikat.length != 0"
            style="max-width: 40rem;"
            class="mb-2"
        >
            <div class="text-center">
                <img v-if="data_sertifikat[0].sudah_ditandatangani" width="100" height="100" src="/media/checkfile.png" alt="check-document"/>
                <img v-else width="100" height="100" src="/media/uncheckfile.png" alt="check-document"/>
                
            </div>
            <b-card-text class="mt-5">
            <span v-if="data_sertifikat[0].sudah_ditandatangani">Dokumen ini sudah di tanda tangan secara elektronik oleh Rosan Perkasa Roeslani selaku Menteri Investasi dan Hilirisasi/Kepala BKPM </span>
            <span v-else>Dokumen ini belum di tanda tangan secara elektronik</span>

            <hr>
            <h5>Status Dokumen</h5>
            <b-badge v-if="data_sertifikat[0].sudah_ditandatangani" pill variant="success">Sudah ditandatangani</b-badge>
            <b-badge v-else pill variant="danger">Belum ditandatangani</b-badge>
            <h5 class="mt-2">Jenis Dokumen</h5>
            <span>{{ data_sertifikat[0].jenis_sertifikat }}</span>
            </b-card-text>

            <b-button v-if="data_sertifikat[0].sudah_ditandatangani" :href="data_sertifikat[0].file_final_sertifikat" target="blank" variant="primary"><i class="fa fa-download"></i> Unduh Dokumen</b-button>

            <hr>
            <div class="mt-5 text-center">
                <img class="m-2" width="200px" src="/media/logos/logopanjangbaru.png" alt="">
                <img class="m-2" width="200px" src="/media/bsree.png" alt="">
            </div>
        </b-card>

        <b-card v-else>
            <b-card-text>
                Dokumen tidak ditemukan!
            </b-card-text>
        </b-card>
    </div>
</div>
</template>

<script>
import axios from "axios";
export default {
    data(){
        return {
            url: localStorage.getItem("baseapi"),
            data_sertifikat: []
        }
    },
    mounted(){
        this.loadData()
    },
    methods:{
        loadData(){
            axios.get(`${this.url}/sertifikat_penkin?filter=id,=,${this.$route.query.id}`).then((res) => {
                this.data_sertifikat = res.data.data
            })
        }
    }
}
</script>